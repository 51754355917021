import React, { useState } from 'react';
import '../../css/Home/Faq.css';
import { COLORS } from '../../components/reuseables/constants';
import SearchBox from '../SearchBox';
import { IoIosArrowDown, IoIosArrowUp  } from "react-icons/io";


const datas = [
	{
		id: 1,
		question: 'What is LambaTask (LT)?',
		answer: 'An LT is a step-by-step request to do publicity for a brand using content creation and the distribution of the content with a link connecting the brand.'
	},
	{
		id: 2,
		question: 'How do winners emerge from a LambaTask (LT)?',
		answer: '	Winners emerge by votes, a vote is the combination of two items, the LambaCount ( LC ) and the LambaWeight ( LW )'
	},
	{
		id: 3,
		question: 'How can I use my product or service as a reward for a LambaTask?',
		answer: 'For a product, you will need to send them to our collection point first, while services or certain products as rewards can be converted to vouchers.'
	},
	{
		id: 4,
		question: 'Who can use this platform?',
		answer: 'Our platform is open to all individuals and brands. Go creative in creating Lambatasks and enjoy exponential publicity.'
	}
]
const Faq = () => {

	const Accordion = ({ data: {id, question, answer}}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
					<div className="accordion-c">
						<h3>{question}</h3></div>
        <div className="accordionButton">{isActive ? <IoIosArrowUp color={COLORS.PRIMARY} size={20}/> : <IoIosArrowDown color={COLORS.PRIMARY} size={20} /> }</div>
      </div>
				{isActive && <div className="accordion-content">
					<p>{answer}</p>
				</div>}
    </div>
  );
};
		return (
				<>
				<section className="faq">
					<div className="container partner-p">
						<div className="row">
							<div className="faq-title">
								<h2 className="text-center text-white">Frequently Asked <span style={{ color: COLORS.PRIMARY }}>questions</span></h2>
								<p>Can’t find the answer you’re looking for ? Reach out to our support team</p>
								</div>
						</div>
					</div>
					<div>
					<div className="container">
							<div className="row">
							<SearchBox />
								<div className="faq-card">
									{datas.map((data, i) => (
										<Accordion
											key={i}
											data={data} />
									))}
									
								</div>
								
							
						</div>
						</div>
						</div>
					</section>
				</>
		)
}

export default Faq
