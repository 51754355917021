import React, { useState } from 'react'
import '../../css/Creator/TabArea.css'
import { RiShieldStarLine, RiTrophyLine, RiClipboardLine } from "react-icons/ri";
import MobileStoreButton from 'react-mobile-store-button';

const TabArea = ({ creator,task }) => {

	const iOSUrl = 'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';
	const androidUrl = 'https://play.google.com/store/apps/details?id=com.daveblaq.lambabros';

	const [activeTab, setActiveTab] = useState("tab1");

	const [copySuccess, setCopySuccess] = useState('');

// your function to copy here

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

	const clickHandler = param => event => {
		setActiveTab(param);
		console.log(param); // your parameter
		console.log(event.type); // event type, e.g.: click, etc.
	};


	const TabHeader = () => {
		return (
			<div className="w-full p-4 flex">

				<div onClick={(e) => clickHandler('tab1')(e)} className={`w-1/2 flex items-center justify-center  py-4 cursor-pointer ${activeTab === 'tab1' ? 'tabActive' : 'tabBottom'}`}>
					<h2 className="text-white text-xl leading-7 headText">How to vote</h2>
				</div>
				<div onClick={(e) => clickHandler('tab2')(e)} className={`w-1/2 flex items-center justify-center  py-4 cursor-pointer ${activeTab === 'tab2' ? 'tabActive' : 'tabBottom'}`}>
					<h2 className="text-white text-xl leading-7 headText">How to participate</h2>
				</div>


			</div>
		)
	}

	const CreatorsInfo = ({ creator }) => {
		return (
			<div className="grid md:grid-cols-2 gap-5 py-2 my-4 flex ">
				<div className="w-full">
					<div className="creatorCard p-4 flex">
						<div className="userAvatar h-20 w-20 rounded-full border-dashed border-2 border-white flex items-center justify-center">
						<img className="rounded-full" src={creator.user.profile_pic}
										alt="" />
						</div>
						<div className="ml-6">
							<h2 className="text-white text-xl pb-2 bodyText">{creator.fullname}</h2>
							<div className="flex align-center">
								<RiShieldStarLine color="#51BBFE" size={20} /> <h2 className="text-white opacity-40 text-xl pb-1 font-normal pl-2 bodyText"># Rank {creator.rank}</h2>
							</div>
							<div className="flex align-center">
								<RiTrophyLine color="#00CC66" size={20} /> <h2 className="text-white opacity-40 text-xl pb-1 font-normal pl-2 bodyText">{ creator.user.tasks_participated } tasks Completed</h2>
							</div>
						</div>


					</div>
				</div>
				<div className="w-full">
					<div className="voteCard pl-10">
						
						<ul className="tab-nav list-none pl-0 my-4">
							<li className="bodyText text-white py-2">· Download the App :<br/>
							
				<MobileStoreButton
				  store="android"
				  url={androidUrl}
				  linkProps={{ title: 'Play Store Button' }}
				/>
							</li>
							<li className="bodyText text-white py-2">· Sign up or login if you have an account
							</li>
							<li className="bodyText text-white py-2">·	Click to copy creator code below
							</li>
							<li className="bodyText text-white py-2" onClick={() => copyToClipBoard('c:'+creator.hash)}>
							<div className='row'>
							<div className="w-44 py-2 border flex items-center justify-center rounded-full mb-2 col-md-5">
							<h2 style={{ color: "#FFC817"}} className="text-xl headText">c:{ creator.hash } </h2>
						</div>
								
								<div className='col-md-4'>
								<RiClipboardLine className='inline' color="#FFC817" size={20} />
  
						   			{copySuccess}
								</div>
								
								</div>
							

							</li>
							<li className="bodyText text-white py-2">· Click on the Lamba Task Section(In App)
							</li>
							<li className="bodyText text-white py-2">· Click on the campaigns
							</li>
							<li className="bodyText text-white py-2">· Paste creators code in the search box
							</li>
							<li className="bodyText text-white py-2">· Open creators page and vote
							</li>
							
							
						
						</ul>
					</div>
					</div>

			</div>
		)
	}

	const TaskDetails = ({ creator,task }) => {
		return (
			<div className="grid md:grid-cols-2 gap-5 py-2 my-4 flex ">
				<div className="w-full sm:pb-5">
					<div className="creatorCard p-8">
						<h2 className="text-white opacity-50 bodyText text-base font-normal pb-2">Task Description</h2>
						<h2 className="text-white bodyText text-base font-normal pb-2">{task.description}</h2>


					</div>
				</div>
				<div className="w-full">
					<div className="voteCard pl-10">
						
						<ul className="tab-nav list-none pl-0 my-4">
							<li className="bodyText text-white py-2">· Download the App Here
							</li>
							<li className="bodyText text-white py-2">· Sign up or login if you have an account
							</li>
							<li className="bodyText text-white py-2">· Click on the Lamba Task
							</li>
							<li className="bodyText text-white py-2">· Click on the campaigns
							</li>
							<li className="bodyText text-white py-2">·	Search for creators usersname and vote
							</li>




						</ul>
					</div>
				</div>

			</div>
		)
	}
	return (
		<>
			<section className="py-8">
				<div className="container">
					<div >
						<TabHeader />
						{activeTab === 'tab1' ? <CreatorsInfo creator={creator}  task={task}/> : <TaskDetails creator={creator}  task={task}/>}
						
					</div>
				</div>
			</section>
		</>
	)
}

export default TabArea
