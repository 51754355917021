import {
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './pages/HomePage'
import Creator from './pages/Creator'
import './css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
//import { Provider } from "react-redux";
//import store from './components/redux/store'

const App = () => {
  AOS.init();
  return (
   
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/brands" element={<HomePage />} />
      <Route path="/creator/:shareUrl" element={<Creator />} />
    </Routes>

  );
}

export default App;
