import React from 'react';
import '../../css/Home/Cta.css';
import RectangleButton from '../Buttons/RectangleButton'

const CtaTwo = () => {
		return (
				<>
						<section className="cta">
								<div className="container">
						<div className="row">
							<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
								<div className="cta-text-section" data-aos="fade-down-right">
									<h2>Interested to <span style={{ opacity: 0.5}}>work with us?</span></h2>
                                    <p>Be intentional about your growth, do something different and unique to grow your business or brand by creating more social proof and endorsement with us.</p>
								
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
								<div className="cta-button-section" data-aos="fade-down-left">
								<RectangleButton />
								</div>
								</div>
								</div>
								</div>
							</section>
				</>
		)
}

export default CtaTwo
