import React from 'react';
import mobile from '../../img/mobile-ui.png'
import '../../css/Home/HowItWorks.css'

const datas = [
		{
			id: 1,
			title: "Connect with us",
		text: "Connect with us by clicking on the get started now button on this page, fill in your details in the form and one of our team members will get across to you.",
			animation: 'fade-right'
		},
		{
			id: 2,
			title: "Mint Collectibles",
			text: "Mint as many vouchers as you want to digitalize your your products and services or run promotions using your lambatoken wallet.",
		animation: 'fade-right'
		}
];
	
const items = [
		{
			id: 1,
			title: "Reward your community",
		text: "You can run promotions for your business or brand using your business collectibles to build that community that will be loyal and advocate for you as they create user-generated contents to help you build and maintain relevance on any platform including ours.",
		animation: 'fade-left'

		},
		{
			id: 2,
			title: "Transform your business",
			text: "Leverage our insights on your collectibles to optimize your sales strategy, targeting the customers who matter most.",
		animation: 'fade-left'
		}
	];

const HowItWorks = () => {
	const Card = ({data: {title, text, animation }}) => {
		return (
			<div className="works-card" data-aos={`${animation}`}>
				<h3 className="works-title">{title}</h3>
				<p>{text}</p>
</div>
		)
	}

		return (
				<>
				<section className="how-it-works" id="work">
					<div className="container py-20">
						<div className="row">
							<h2 className="work-title text-center text-white">How It works</h2>
						</div>
					</div>
					<div style={{ marginTop: '50px' }}>
					<div className="container">
							<div className="row">
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
									<div className="works-row-section">
										{datas.map((data, i) => (
											<Card data={data} key={i} />
										))}
										
										</div>
								</div>
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
									<div className="mobile-card d-flex justify-content-center">
										<img src={mobile} alt="Mobile UI" />
									</div>	
								</div>
								<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
												<div className="works-row-section">
									{items.map((item, i) => (
											<Card data={item} key={i} />
										))}
										</div>
								</div>
							
						</div>
						</div>
						</div>
					</section>
				</>
		)
}

export default HowItWorks
