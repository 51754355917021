import React from 'react';
import '../../css/RectangleButton.css';

const TabContent = ({id, activeTab, children}) => {
    return (
      activeTab === id ? <div className="box-inner-2">
       { children }

      </div>
      
      : null
    );
   };
    
   export default TabContent;
