import React from 'react'
import ReactPlayer from 'react-player'
import '../../css/Creator/VideoArea.css'
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";

import { FaPhoneAlt, FaGlobe } from "react-icons/fa";
import { COLORS } from '../reuseables/constants'

const VideoArea = ({ creator, task }) => {
	const UpVoteButton = () => {
		return (
			<div className="flex pr-8">
				<FiThumbsUp color="rgba(255, 255, 255, 0.7)" size={28} />
				<h2 className="title text-white font-normal pl-2">{creator.upvotes}</h2>
			</div>

		)
	}
	const DownVoteButton = () => {
		return (
			<div className="flex">
				<FiThumbsDown color="rgba(255, 255, 255, 0.7)" size={28} />
				<h2 className="title text-white font-normal pl-2">{creator.downvotes}</h2>
			</div>

		)
	}

	const ConnectButton = () => {
		return (

			<a href={task.brand_cta} className="btn-connect px-2 py-2 flex items-center justify-center pool no-underline">
				<h2 className="no-underline">{task.brand_cta_text}</h2>
			</a>
		)
	}

	return (
		<>
			<section className="py-4">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="video-frame relative">
								<div className="absolute px-2 py-1 bg-black top-4 flex items-center justify-center opacity-70">
									<h2 className="text-xs brand-name" style={{ color: "#ffc817" }}>POWERED BY LAMBATOKEN</h2>
								</div>
								<ReactPlayer
									url={creator.media_url}
									width='100%'
									height='100%'
									controls={true}
								/>
							</div>
							<div className="py-4 flex flex-row content-center justify-between mobile-title">
								<h2 className="text-white title leading-9 sm:leading-7">
									{task.title}
								</h2>
								<div className="flex mobile-btns">
									<UpVoteButton />
									<DownVoteButton />
								</div>
							</div>
							<div className="py-4 flex stat pr-8 justify-between">
								<div className="flex flex-col duration">
									<h3 className="font-normal not-italic leading-8">LambaTask Duration </h3>
									<h2 className="text-white text-xl leading-7 not-italic ">{task.end_date} </h2>
								</div>
								<div className="flex flex-col pool">
									<h3 className="font-normal not-italic leading-8">Reward Pool </h3>
									<h2 className="text-xl leading-7 not-italic font-semibold">{task.pool} LBT </h2>
								</div>
							</div>
						</div>
						<div className="col-md-4 flex flex-col gap-4">
							<div className="px-3 py-3 h-44 5-full bg-fixed brand-img bg-contain relative">
								<img className="h-full w-full block" src={task.media.image_resized}
									alt="" />
								<div className="max-w-fit rounded-full bg-white/60 border-2 absolute bottom-[-35px] left-[30px]">
									<img className="h-20" src={task.brand.logo}
										alt="" />
								</div>
							</div>
							<div className="py-5 px-4 bg-black rounded-md sec">
								<h2 className="brand-name text-white font-normal text-xl leading-6">
									{task.brand.name}
								</h2>
								<h3 className="text-white opacity-50 font-normal text-base pt-3 brand-name">
									About
								</h3>
								<h3 className="text-white font-normal text-base pt-1 brand-name leading-8">
									{task.brand.description}
								</h3>

								<div className="mt-5 items-end justify-end w-full">
									{task.brand_cta ?
										(<ConnectButton />) : (
											<div className='text-center justify-center '>


												<p className="text-white font-normal pl-2">
													<FaPhoneAlt
														color={COLORS.PRIMARY}
														size={20}
														style={{
															display: 'inline',
															
														}} /> &nbsp;{task.brand.phone}</p>
												<p className="text-white font-normal pl-2">
													<FaGlobe
														color={COLORS.PRIMARY}
														size={20}
														style={{
															display: 'inline',
												
														}} /> 
														<a href={task.brand.website} className="inline-block pool no-underline" target="_blank" rel="noopener noreferrer">
				<h2 className="no-underline"> &nbsp;website</h2>
			</a>
														</p>
											</div>

										)

									}
								</div>

							</div>
						</div>
					</div>


				</div>

			</section>
		</>
	)
}

export default VideoArea
