import React from 'react';
import '../../css/Home/WhyWork.css';
import iconone from '../../img/icon-1.svg'
import icontwo from '../../img/icon-2.svg'
import iconthree from '../../img/icon-3.svg'

let datas = [
	{
			id: 1,
    title: "Customer Success Manager",
    image: iconone,
		text: 'For each brand that uses our platform, we set up a specific channel where you can tell us what you need and we can equally offer the finest strategies for making the most of your campaign.',
				animation: 'fade-right'
  },
  {
    id: 2,
    title: "Analytics Report",
    image: icontwo,
			text: 'Get insight into every form of the campaign done on our platform, from the number of views to the number of engagements to the demography of participation.',
				animation: 'fade-down'
  },
  {
   id: 3,
    title: "Chat Support",
    image: iconthree,
			text: "If you wish to run future campaigns, you don't need to go through the process of onboarding a campaign again; all it takes is a quick chat from the channel created for you, and you can start working on your next campaign right away.",
				animation: 'fade-left'
  },
 
];

const WhyWork = () => {
	const WorkCard = ({ data: { title, image, text, animation } }) => {
		return (
			<>
				<div className="workCard" data-aos={`${animation}`}  data-aos-offset="500"
     data-aos-duration="500">
					<img src={image} alt={title} />
					<h3>{title}</h3>
					<p>{text}</p>
				</div>
						
				</>
		)
}
		return (
				<>
				<section className="why-work">
					<div className="container py-20">
						<div className="row">
							<h2 className="work-title text-center text-white">Why <span style={{ opacity: 0.5 }}>work</span> with us</h2>
						</div>
					</div>
					<div style={{ marginTop: '30px' }}>
					<div className="container">
							<div className="row">
								{datas.map((data, i) => (
										<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
								<WorkCard data={data} key={i} />
								</div>
								))}
							
						</div>
						</div>
						</div>
					
						</section>
				</>
		)
}

export default WhyWork
