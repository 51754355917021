import React, { useEffect, useState } from 'react';
import '../App.css'
import Footer from '../components/Home/Footer';
import Preloader from '../components/reuseables/Preloader'
import Cta from '../components/Home/Cta';
import VideoArea from '../components/Creator/VideoArea'
import TabArea from '../components/Creator/TabArea'
import { useParams } from 'react-router-dom';
import {
	clearState,
	fetchData,
	userSelector,
} from "../components/redux/slices/dataReducer";
import { useDispatch, useSelector } from "react-redux";

const Creator = () => {

	// the dynamic pieces of the URL.
	const shareID = useParams();

	const dispatch = useDispatch()

	const { creator,task,isFetching, isSuccess, isError, errorMessage } = useSelector(
		userSelector
	);
 
	

	useEffect(() => {
		dispatch(fetchData({shareID}));
	  }, []);
	
	  useEffect(() => {
		if (isError) {
		  alert(errorMessage);
		  dispatch(clearState());
		  //history.push('/login');
		}
	  }, [isError]);
	


		return (
				<>
				<div className="App">
					<Preloader />

					<VideoArea creator={creator} task={task} />
					<TabArea creator={creator} task={task}/>
					<Cta /> 
					<Footer />
				</div>
				</>
		)
}

export default Creator
