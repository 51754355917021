import React from 'react';
import '../css/SearchBox.css';
import { FaSearch } from "react-icons/fa";
import {COLORS} from './reuseables/constants'

const SearchBox = () => {
		return (
				<>
				<div className="search-box">
					<FaSearch
						color={COLORS.PRIMARY}
						size={20}
						style={{	
							margin: 20,
						}} />
					<input type="text" placeholder="What are you searching for ?" className="search-input" />
						</div>
				</>
		)
}

export default SearchBox
