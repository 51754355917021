import React from 'react';
import '../../css/RectangleButton.css';

const RectangleButton = () => {
		return (
				<>
				<a
					href="https://tally.so/r/mD5aeE" target="_blank" rel="noopener noreferrer" 
					className="rectangle-button">
					Get Started Now
				</a>
				</>
		)
}

export default RectangleButton
