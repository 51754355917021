import React from 'react';
import '../../css/Home/VideoBanner.css';
import RoundButton from '../Buttons/RoundButton';
import { COLORS } from '../reuseables/constants'

const VideoBanner = () => {
		return (
				<>
				<section className="video-banner">
					<div className="container">
						<div className="row">
							<div className="vid-wrapper">
							<div className="side-text-section" data-aos="fade-down">
							<h3>ENDLESS POSSIBILITIES</h3>
							</div>
								<div className="video-section" data-aos="fade-up">
									<div className="video-frame">
									<iframe title="Brand OnBoarding Video" width="100%" height="100%" src="https://www.youtube.com/embed/sRfsacAbEkw">
										</iframe> 
										</div>
									<div className="video-bottom-section">
										<RoundButton route="brand" />
										<h2>give your brand the  <span style={{	color: COLORS.PRIMARY }}>Spotlight</span> it deserves</h2>
								</div>
								</div>
								
								</div>
					</div>
					</div>
				</section>	
				</>
		)
}

export default VideoBanner
