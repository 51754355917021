import React from 'react';
import '../../css/RoundButton.css';
import { AiOutlineArrowDown } from 'react-icons/ai';
import {COLORS} from '../reuseables/constants'

const RoundButton = ({route}) => {
		return (
				<>
				<a href={`#${route}`} className="round-button">
					<AiOutlineArrowDown color={COLORS.PRIMARY} size={36} />
				</a>
				</>
		)
}

export default RoundButton
