import React from 'react';
import '../../css/Home/Footer.css';
import logo from '../../img/Lambatoken.png';


const menu = [
		{
			id: 1,
    name: "Quick links",
    links: [
      { title: "What is LBT", url: "https://lambatoken.com" },
     { title: "HOL APP", url: "https://play.google.com/store/apps/details?id=com.daveblaq.lambabros&gl=GB" },
      { title: "Our Token", url: "https://lambatoken.com" },
      { title: "About Us", url: "https://lambatoken.com" },
    ]
  },
		{
			id: 2,
    name: "Socials",
    links: [
      { title: "Facebook", url: "https://web.facebook.com/lambatoken" },
       { title: "Instagram", url: "https://instagram.com/lambatoken" },
     { title: "Twitter", url: "https://twitter.com/lambatoken?t=mdH61xhaASH0N7ZE6-T4ZA&s=09" },
      { title: "LinkedIn", url: "https://www.linkedin.com/company/lambatoken/about/?viewAsMember=true" },
    ]
  }
];
const Footer = () => {
		return (
			<>
				<footer className="footer">
					<div className="container">
						<div className="row foot">
							<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
								<div className="footer-about">
									<img src={logo} alt="logo" />
									<p>LambaToken is a digital asset built on blockchain technology to facilitate and govern transactions in our ecosystems.</p>
									<h3>Copyright ©2022 All rights reserved | Lambabros Deals</h3>
								</div>
							</div>
							{menu.map(({ name, links}) => (
									<>
											<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 line" key={name.id}>
								<div className="footer-menu">
											<h2>{name}</h2>
											{links.map(({ title, url }) => (
												<>
													<ul className="footer-nav">
										<li><a
					href={`${url}`} target="_blank" rel="noopener noreferrer">{title}</a></li>
									</ul>
		</>
												))}
									
								</div>
							</div>
									</>
								))}
					
						
							</div>
					</div>
				</footer>
						
				</>
		)
}

export default Footer
