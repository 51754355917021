import React, { useState } from "react";
import '../../css/Home/WhyUs.css';
import { COLORS } from '../reuseables/constants';
import { MdArrowForwardIos } from "react-icons/md";
import TabContent from '../Tab/TabContent'

const WhyUs = () => {
	const [activeTab, setActiveTab] = useState("tab1");

	const clickHandler = param => event => {
		setActiveTab(param);
		console.log(param); // your parameter
		console.log(event.type); // event type, e.g.: click, etc.
	  };

		return (
				<>
				<section className="why-us" id="brand">
					<div className="container">
						<div className="row">
							<div className="why-text-section">
								<h2>How we can help <span style={{	color: COLORS.PRIMARY }}>your brand</span></h2>
							</div>
						</div>
					</div>
							<div className="container">
						<div className="row">
							<div class="why-container">
								<div className="box-1">
									
									<div onClick={(e) => clickHandler('tab1')(e)} className={activeTab === 'tab1' ? "tab active" : "tab"}>
										<h3>Flexibility</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									<div onClick={(e) => clickHandler('tab2')(e)} className={activeTab === 'tab2' ? "tab active" : "tab"}>
										<h3>Cost-Effective Solutions</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									<div onClick={(e) => clickHandler('tab3')(e)} className={activeTab === 'tab3' ? "tab active" : "tab"}>
										<h3>Enhanced Customer Engagement</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									<div onClick={(e) => clickHandler('tab4')(e)} className={activeTab === 'tab4' ? "tab active" : "tab"}>
										<h3>Security and Transparency</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									<div onClick={(e) => clickHandler('tab5')(e)} className={activeTab === 'tab5' ? "tab active" : "tab"}>
										<h3>Scalability</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									<div onClick={(e) => clickHandler('tab6')(e)} className={activeTab === 'tab6' ? "tab active" : "tab"}>
										<h3>Innovation and Differentiation</h3>
										<MdArrowForwardIos color={COLORS.PRIMARY} size={20}/>
									</div>
									
								</div>
								<div className="box-2">
								<TabContent id="tab1" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>Your customers are not limited to cash payments. With Lambatoken, your business can accept digital collectibles as payment or issue vouchers as rewards, creating a seamless and modern transaction experience.</p>
										<p>Our platform easily integrates with your existing tools, allowing you to create and manage collectibles without disrupting what you’re already familiar with. These digital assets are simple to create and can be used and promoted on any platform of your choice, giving you flexibility and control over how you engage with your customers.</p>
									</div>
									
        						</TabContent>	
								<TabContent id="tab2" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>Reduce overhead with our blockchain-based platform. By leveraging NFTs, you can cut costs associated with traditional marketing and loyalty programs while offering innovative, value-driven incentives to your customers.</p>
									</div>
									
        						</TabContent>	
								<TabContent id="tab3" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>Strengthen your customer relationships by offering unique, branded digital collectibles. These can be traded, collected, or redeemed, encouraging repeat business and fostering long-term loyalty.</p>
										<p>Humans, not AI or algorithms, are utilizing their online power to spread the word about you 
										because they have motivation, passion, creativity, and inspiration.
										</p>

									</div>
									
        						</TabContent>	
								<TabContent id="tab4" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>Ensure the authenticity and security of your brand’s digital assets. Our platform uses blockchain technology to guarantee that every transaction is transparent, secure, and tamper-proof, building trust with your customers.</p>
									</div>
									
        						</TabContent>	
								<TabContent id="tab5" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>As your brand grows, so can your digital offerings. Lambatoken’s platform is designed to scale with your business, allowing you to expand your digital collectible ecosystem without the limitations of traditional systems.</p>
										<p>Ripple effect – A completed campaign can continue to produce significant result since more than one person carries out this campaign task.</p>

									</div>
									
        						</TabContent>	
								<TabContent id="tab6" activeTab={activeTab}>
									<div className="box-inner-1">
										<h3>Our Advantage</h3>
										<p>Stand out in a crowded market by embracing the future of digital interaction. Lambatoken empowers your brand to innovate by turning products and services into cutting-edge digital collectibles, setting you apart from competitors and attracting new customers.</p>
									</div>
									
        						</TabContent>	
									
									
								</div>
							</div>
						</div>
						</div>
			</section>
				</>
		)
}

export default WhyUs
