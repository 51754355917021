import React from 'react';
import Hero from '../components/Home/Hero';
import WhyWork from '../components/Home/WhyWork';
import Cta from '../components/Home/Cta';
import CtaTwo from '../components/Home/CtaTwo';
import Partners from '../components/Home/Partners';
import HowItWorks from '../components/Home/HowItWorks';
import Faq from '../components/Home/Faq';
import LetsChat from '../components/Home/LetsChat';
import VideoBanner from '../components/Home/VideoBanner';
import WhyUs from '../components/Home/WhyUs';
import Footer from '../components/Home/Footer';
import Preloader from '../components/reuseables/Preloader'
import '../App.css'


const HomePage = () => {
	return (
		<>

			<div className="App">
				<Preloader />
				<Hero />
				<HowItWorks />
				<Cta />
				<VideoBanner />
				<WhyUs />
				<WhyWork />
				<Partners />
				<Faq />
				<LetsChat />
				<CtaTwo />
				<Footer />
			</div>
		</>
	)
}

export default HomePage
