import React from 'react';
import '../../css/Home/Partners.css'
import { COLORS } from '../reuseables/constants'
import propertiespartners from '../../img/logo-1.svg';
import geo from '../../img/logo-2.svg';
import haxel from '../../img/logo-3.svg';
import pinksale from '../../img/logo-4.svg';


const partners = [
	{
		id: 1,
		image: propertiespartners,
	},
		{
		id: 2,
		image: geo,
		},
			{
		id: 3,
		image: haxel,
	},
			{
		id: 4,
		image: pinksale,
	}
]

const Partners = () => {
		return (
				<>
				<section className="partners">
					<div className="container partner-p">
						<div className="row">
							<div className="partner-title">
								<h2 className="text-center text-white"><span style={{ color: COLORS.PRIMARY }}>lambatoken</span> has partnered with  <span style={{ opacity: 0.5 }}>leading companies & organizations</span> around the globe.</h2>
								</div>
						</div>
					</div>
					<div style={{ marginBottom: '50px' }}>
					<div className="container">
							<div className="row">
								<div className="partners-logos">
									{partners.map((partner) => (
										<img src={partner.image} alt='' key={partner.id} data-aos="fade-down" data-aos-offset="500"
											data-aos-duration="500" />
									))}
									
									
							</div>
							
						</div>
						</div>
						</div>
					</section>
				</>
		)
}

export default Partners
