
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseURL } from "../../api/api";

async function fetchWithTimeout(resource, options = {}) {
	const { timeout = 5000 } = options;

	const abortController = new AbortController();
	const id = setTimeout(() => abortController.abort(), timeout);
	const response = await fetch(resource, {
		...options,
		signal: abortController.signal,
	});
	clearTimeout(id);
	return response;
}

const initialState = {
	
	creator: {
		id : null,
		task_id : null,
		user_id: null,
		fullname: null,
		media_url: null,
		lamba_weight: null,
		lamba_count: null,
		upvotes: 0,
		downvotes: 0,
		total: 0,
		rank: 0,
		share_url: null,
		created_at: null,
		updated_at: null,
		user: {
			id: null,
			username: null,
			firstname: null,
			lastname: null,
			email: null,
			phone: null,
			phone_national: null,
			phone_e164: null,
			country: null,
			referral_code: null,
			profile_pic: null,
			email_verified_at: null,
			phone_verified_at: null,
			last_login: null,
			status: null,
			tasks_participated: 0,
			tasks_voted: 0,
			games_participated: 0,
			games_voted: 0,
			created_at: null,
			updated_at: null,
		}
 
	},

	task: {
		id: null,
		brand_id: null,
		title: null,
		tagline: null,
		description: null,
		start_date: null,
		end_date: null,
		pool: null,
		trending: null,
		reward: null,
		participants: null,
		votes: null,
		weight: null,
		brand_cta: null,
		cta_text: null,
		status: null,
		created_at: null,
		updated_at: null,
		media: {
			id: null,
			task_id: null,
			image_original: null,
			image_resized: null,
			image_thumbnail: null,
			video_url: null,
			reward_original: null,
			reward_resized: null,
			created_at: null,
			updated_at: null,
		},
		brand: {
			id: null,
			name: null,
			type: null,
			address: null,
			phone: null,
			website: null,
			zipcode: null,
			description: null,
			state: null,
			country: null,
			logo: null,
			created_at: null,
			updated_at: null
		}
	},
	
	isFetching: false,
	isSuccess: false,
	isError: false,
	errorMessage: "",
};

export const fetchData = createAsyncThunk(
	"apidata",
	async ({shareID}, thunkAPI) => {
		try {
			const response = await fetchWithTimeout(BaseURL + '/share/task/'+ shareID.shareUrl, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				timeout: 15000,
			});

			let data = await response.json();
			console.log("data", data, response.status);

			if (response.status === 200) {
				return data ;
			} else {
				//return api data with errors to reducer :rejected
				return thunkAPI.rejectWithValue(data);
			}
		} catch (error) {
			//catch network error
			if (error.message === "Network request failed") {
				// return user friendly message to reducer :rejected
				const cr = { message: "Network request failed" };
				return thunkAPI.rejectWithValue(cr);
			}
			//catch request timeout or cancelled
			if (error.message === "Aborted") {
				//return user friendly message to reducer :rejected
				const cr = {
					message: "Network timed out. Check your network and retry.",
				};
				return thunkAPI.rejectWithValue(cr);
			} else {
				//every other error should fail gracefully
				const cr = { message: "Something went wrong" };
				return thunkAPI.rejectWithValue(cr);
			}
		}
	}
);

const dataReducer = createSlice({
	name: "apidata",
	initialState,
	reducers: {
		clearState: (state) => {
			state.isError = false;
			state.isSuccess = false;
			state.isFetching = false;

			return state;
		},
	},

	extraReducers: {
		[fetchData.fulfilled]: (state,  { payload }) => {
			state.isFetching = false;
			state.isSuccess = true;
			state.creator = payload.data.creator;
			state.task = payload.data.task;
		},
		[fetchData.pending]: (state, action) => {
			state.isFetching = true;
		},
		[fetchData.rejected]: (state,  { payload }) => {
			if (payload.errors) {
				var msg = '';
				if (payload.errors.share_url) {
				  msg = payload.errors.share_url[0];
				  state.errorMessage = msg;
				}
				
			}
			  else {
				state.errorMessage = payload.message;
			}

			state.isFetching = false;
			state.isError = true;
		},
	},
});

export const { clearState } = dataReducer.actions;
export const userSelector = (state) => state.apidata;
export const selectLoader = (state) => state.apidata.isFetching;
export default dataReducer.reducer;