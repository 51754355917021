import React, {useState, useEffect} from 'react';
import styles from '../../css/Preloader.module.css';
import lambalogo from '../../img/logo.png';

function Preloader() {

    const [isActive,
        setIsActive] = useState(true);
    const [close,
        setClose] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsActive(false)
        }, 4000);
    }, [])


    setTimeout(() => {
        setClose(true)
    }, 4500)

    return (
        <div className={`${close && styles.close} ${styles.preloaderHolder}`}>
            <div className={`${isActive ? '' : styles.fadeOut} ${styles.content}`}>
                <div>
                   

                    <div className={`alignOnly mt-5 ${styles.loaderHolder}`}>
									<div className={styles.loader}>
										<div className={styles.logoBox}>
																			<img src={lambalogo} alt="" />
																</div>
																								</div>
                    </div>
                </div>
            </div>
            <div className={styles.left}></div>

            <div className={styles.right}></div>
        </div>
    )
}

export default Preloader