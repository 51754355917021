import React from 'react';
import '../../css/Home/LetsChat.css';
import {COLORS} from '../reuseables/constants';
import facebook from '../../img/facebook.svg';
import instagram from '../../img/instagram.svg';
import twitter from '../../img/twitter.svg';
import linkedin from '../../img/linkedin.svg';



const items = [
	{
		id: 1,
		text: 'Facebook',
		image: facebook, 
		animation: 'fade-right',
		link: 'https://web.facebook.com/lambatoken'
	},
	{
		id: 2,
		text: 'Instagram',
		image: instagram,
		animation: 'fade-left',
		link: 'https://instagram.com/lambatoken'
	},
	{
		id: 3,
		text: 'Twitter',
		image: twitter,
		animation: 'fade-right',
		link: 'https://twitter.com/lambatoken?t=mdH61xhaASH0N7ZE6-T4ZA&s=09'
	},
	{
		id: 4,
		text: 'LinkedIn',
		image: linkedin,
		animation: 'fade-left',
		link: 'https://www.linkedin.com/company/lambatoken/about/?viewAsMember=true'
	}
]
const LetsChat = () => {
	const SocialCard = ({ data: {text, image, animation, link}}) => {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer">
				<div className="social-card" data-aos={`${animation}`}>
				<img src={image} alt={text} />
				<h3>{text}</h3>
			</div>
			</a>
			
		)
	}
		return (
			<>
				<section className="lets-chat">
						<div className="container py-20">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
								<div className="text-content">
									<h3>
										<span style={{ color: COLORS.PRIMARY }}>Let’s Chat </span><br />
										We respond fast
									</h3>
									<p>Our support team is always available</p>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
								<div className="cards-content">
									{items.map((item, i) => (
										<SocialCard data={item} key={i} />
									))}
								
									</div>
							</div>
					</div>
					</div>
				</section>
						
				</>
		)
}

export default LetsChat
