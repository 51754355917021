import React from 'react'
import '../../css/Home/Hero.css'
import { COLORS } from '../reuseables/constants'
import RoundButton from '../Buttons/RoundButton';
import twitter from '../../img/brand-1.png';
import tiktok from '../../img/brand-2.png';
import facebook from '../../img/brand-3.png';
import telegram from '../../img/brand-4.png';
import instagram from '../../img/brand-5.png';
import lambalogo from '../../img/logo.png';

const logos = [
	{
		id: 1,
		image: twitter,
	},
	{
		id: 2,
		image: tiktok,
	},
	{
		id: 3,
		image: facebook,
	},
	{
		id: 4,
		image: telegram,
	},
	{
		id: 5,
		image: instagram,
	}
]
const Hero = () => {
	return (
		<>
			<section className="hero">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div className="hero-text-section" data-aos="fade-down">
								<h2>
								Revolutionize and Monetize Your Brand with <span style={{ color: COLORS.PRIMARY }}>Lambatoken</span>
								</h2>
								<p className="text-center text-md-start" style={{ color: COLORS.WHITE }}>where businesses, services, and products transform into valuable digital collectibles.</p>
								<div style={{ paddingTop: '34px' }}>
									<RoundButton route="work" />
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
							<div className="orbit" data-aos="fade-up">
								<div className="hero-orbit-section">
									{logos.map((logo, i) => (
										<div className={`orbit-card child-${logo.id}`} key={i}>
											<img src={logo.image} className="swingimage" alt="Brands" />
										</div>
									))}
									<div className="animation">
										<div className="inner-brand-logo">
											<img src={lambalogo} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Hero
